


<style>
/* .serviceCards{
  display: flex;

} */
.serviceCard {
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: black !important;
}

.serviceCard .infoPart p {
  font-weight: 400;
  color: black;
  line-height: 150%;
  display: inline;
}
.serviceCard .infoPart #readmore {
  font-size: 14px;
  color: black;
  margin-left: 4px;
  font-weight: 700;
  text-decoration: none !important;
}

/* 
.serviceCard svg {
  min-width: 150px;
  padding: 1.5rem;
} */
.serviceCard .v-card__actions {
  width: 100%;
  order: 3;
}
.serviceCard .v-card__title {
  font-size: 32px;
}
.serviceCard .v-card__title .title_icon {
  width: 150px;
  height: 150px;
  padding: 13px;
  border: 1px solid #8f8f8f54 !important;
  border-radius: 50%;
}

.serviceCard .v-card__title .title_icon svg {
  width: 100%;
  height: 100%;
}

.serviceCard .v-card__text {
  font-size: 24px;
  font-weight: 300 !important;
  font-family: "system-ui";
  line-height: 120%;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 8px;
}
@media only screen and (max-width: 600px) {
  .serviceCard {
    padding: 4px;
    flex-direction: column;
  }

  .serviceCard .infoPart {
    width: 100%;
    order: 2;
  }
  .serviceCard .v-card__title {
    font-size: 22px;
    padding: 8px;
  }
  .serviceCard .v-card__text {
    font-size: 16px;
    padding: 0;
    padding: 8px;
    line-height: 120%;
  }

  .serviceCard .v-card__title .title_icon {
    width: 100px;
    height: 100px;
  }
}
/* .mainCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff45;
  border-radius: 16px !important;
} */

/* 


.logoCard.aos-animate svg g#letters path {
  stroke-dashoffset: 0;
}
 */
@keyframes dash {
  75% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}

.slidingDown {
  animation: slidingDown 4s ease infinite;
  opacity: 0;
}
@keyframes slidingDown {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}
.slideRight {
  animation: slideRight 4s ease infinite;
}
@keyframes slideRight {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
.page {
  position: relative;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0%;
  min-width: 100%;
  padding: 0%;
}

.bgSlideShow {
  position: absolute !important;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.topSlideShow {
  position: relative;
  width: 100%;
}
.overlay {
  position: absolute !important;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.bgSlideShow * {
  height: 100% !important;
}
.bgSlideShow .v-image__image {
  filter: blur(8px) grayscale(0.7) contrast(0.9);
}

/* 
.drawIt {
  fill-opacity: 0;
  stroke: black;
  stroke-dasharray: 10000;
  stroke-dashoffset: 10000;
  animation: dash all 4s ease 1s, fill-opacity 3s ease 1.5s;
}
.aos-animate .drawIt {
  fill-opacity: 1;
  stroke-dashoffset: 0;
} */
</style>
<template>
  <div class="home">
    <v-dialog v-model="thankYouDialog" hide-overlay max-width="500">
      <v-card class="pa-2">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 capitalize">
              {{ $t("thankYou") }}.
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t("weConfirmBooking") }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-avatar tile size="75"
            ><v-icon color="green" x-large
              >mdi-check</v-icon
            ></v-list-item-avatar
          >
        </v-list-item>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="bookingDialog"
      hide-overlay
      :persistent="BookingIsLoading"
      :max-width="$vuetify.breakpoint.mdAndUp ? '500' : ''"
    >
      <v-card :loading="BookingIsLoading" class="pa-2">
        <v-card-title class="headline px-1 capitalize">
          {{ $t("book") }}
        </v-card-title>
        <v-form
          :disabled="BookingIsLoading"
          v-model="bookingForm"
          lazy-validation
          class="px-8"
          ref="form"
        >
          <v-col cols="12">
            <v-select
              :items="this.services.map(s => $t(s.title))"
              solo
              label="Service Type"
              :value="selectedService"
            >
            </v-select>
          </v-col>

          <v-text-field
            prepend-inner-icon="mdi-account"
            :rules="[rules.required]"
            :label="$t('fullName').toUpperCase()"
            hide-details
            required
            outlined
          ></v-text-field>
          <v-divider class="my-2"></v-divider>
          <v-text-field
            prepend-inner-icon="mdi-email"
            :label="$t('email').toUpperCase()"
            :rules="[rules.required, rules.email]"
            hide-details
            outlined
            required
          ></v-text-field>
          <v-divider class="my-2"></v-divider>
          <v-text-field
            prepend-inner-icon="mdi-map-marker"
            hide-details
            :rules="[rules.required]"
            :label="$t('address').toUpperCase()"
            outlined
          ></v-text-field>
          <v-divider class="my-2"></v-divider>
          <v-text-field
            prepend-inner-icon="mdi-phone"
            hide-details
            required
            :rules="[rules.required]"
            :label="$t('phoneNumber').toUpperCase()"
            outlined
          ></v-text-field>
          <v-divider class="my-2"></v-divider>
          <v-text-field
            required
            hide-details
            :rules="[rules.required]"
            :label="$t('spaceMeters').toUpperCase()"
            outlined
          ></v-text-field>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="BookingIsLoading"
            text
            @click="bookingDialog = false"
          >
            {{ $t("cancel").toUpperCase() }}
          </v-btn>
          <v-btn
            :disabled="BookingIsLoading"
            text
            color="blue"
            dark
            @click="validate()"
          >
            {{ $t("send").toUpperCase() }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="page align-start white">
      <VueSlickCarousel
        style="
"
        class="bgSlideShow"
        v-bind="sliderSettings"
      >
        <v-img
          v-for="(img, i) in slides"
          :key="i"
          height="100%"
          cover
          style=""
          :src="img"
        ></v-img>
      </VueSlickCarousel>
      <div class="overlay glass2"></div>
      <v-card height="250px" class="topSlideShow">
        <VueSlickCarousel v-bind="sliderSettings">
          <v-img
            v-for="(img, i) in slides"
            :key="i"
            height="250px"
            cover
            style=""
            gradient="180deg, black, transparent"
            :src="img"
          ></v-img>
        </VueSlickCarousel>
        <div class="d-flex overlay ">
          <v-card-title class="ma-auto bordered-title white--text pa-4">
            <span>{{ $t("ourServices") }}</span>
          </v-card-title>
        </div>
      </v-card>

      <v-card class="serviceCards" color="transparent">
        <v-card
          color="transparent"
          tile
          flat
          v-for="(s, x) in this.services"
          v-bind:key="x"
          class="serviceCard"
          dark
        >
          <v-card-title
            class="black--text w-100"
            data-aos-duration="600"
            data-aos-offset="-300"
            data-aos="fade-right"
          >
            {{ $t(s.title) }}
            <v-divider color="grey"></v-divider>
            <v-card
              data-aos-duration="600"
              data-aos-offset="-300"
              data-aos="zoom-in"
              data-aos-delay="600"
              class="title_icon"
              color="white"
              outlined
              flat
            >
              <inline-svg
                :src="require('../assets/icons/' + s.icon + '.svg')"
              />
            </v-card>
          </v-card-title>
          <div class="infoPart">
            <v-card-text
              data-aos-duration="600"
              data-aos-offset="-300"
              data-aos-delay="300"
              data-aos="fade-up"
            >
              <!--     <p
                v-line-clamp:20="s.learnMore ? 0 : 3"
                v-html="$t(s.content)"
              ></p> -->
              <read-more
                :more-str="$t('readMore')"
                :less-str="$t('showLess')"
                :maxChars="200"
                :text="$t(s.content)"
              ></read-more>
              <!--     <span>Readmore </span> -->
            </v-card-text>
            <v-card-actions class="text-center">
              <!--      <v-btn
                outlined
                style="color:black"
                class="mr-auto"
                @click="s.learnMore = !s.learnMore"
                v-text="s.learnMore ? $t('showLess') : $t('readMore')"
              ></v-btn> -->
              <v-btn
                dark
                class="px-8 mx-auto"
                @click="
                  selectedService = $t(s.title);
                  formEmail = '';
                  formAddress = '';
                  formPhone = '';
                  formSpace = '';
                  formName = '';
                  bookingForm = false;
                  BookingIsLoading = false;
                  thankYouDialog = false;
                  bookingDialog = true;
                "
                color="blue darken-2"
              >
                {{ $t("book") }}
                <v-icon class="ml-2">mdi-book </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-card>
      <!--  <v-card tile height="300" pa-0 color="transparent">
        <v-carousel height="300" hide-delimiters cycle :show-arrows="false">
          <v-carousel-item
            reverse-transition="fade-transition"
            transition="fade-transition"
            v-for="(img, i) in slides"
            :key="i"
          >
            <v-sheet height="100%" tile>
              <v-img height="300" :src="img"></v-img>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <v-card-title
          style="position: absolute;
    bottom: 0px;
    left: 0px;
    background: #0000005c!important;
    color: white;
    font-size: 32px;"
        >
          <span>{{ $t("ourServices") }}</span>
        </v-card-title>
      </v-card>
      <v-card tile flat>
   
        <v-card class="serviceCards">
          <v-card
            color="transparent"
            tile
            flat
            v-for="(s, x) in this.services"
            v-bind:key="x"
            class="serviceCard"
            dark
          >
            <v-card-title
              class="black--text w-100"
              data-aos-duration="600"
              data-aos="fade-right"
            >
              {{ $t(s.title) }}
              <v-divider color="grey"></v-divider>
            </v-card-title>
            <div class="infoPart">
              <v-card-text
                data-aos-duration="600"
                data-aos-delay="300"
                data-aos="fade-up"
              >
                <p
                  v-line-clamp:20="s.learnMore ? 0 : 3"
                  v-html="$t(s.content)"
                ></p>
              </v-card-text>
              <v-card-actions class="text-center">
                <v-btn
                  outlined
                  style="color:black"
                  class="mr-auto"
                  @click="s.learnMore = !s.learnMore"
                  v-text="s.learnMore ? $t('showLess') : $t('readMore')"
                ></v-btn>
                <v-btn
                  dark
                  depressed
                  class="pa-6 ml-auto"
                  @click="
                    selectedService = $t(s.title);
                    bookingDialog = true;
                  "
                  color="blue darken-2"
                >
                  {{ $t("book") }}
                  <v-icon class="ml-2">mdi-book </v-icon>
                </v-btn>

              </v-card-actions>
            </div>
            <inline-svg
              data-aos-duration="600"
              data-aos="zoom-in"
              data-aos-delay="600"
              :src="require('../assets/icons/' + s.icon + '.svg')"
            />
          </v-card>
        </v-card>
      </v-card> -->
    </v-container>
    <v-row> </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Services",
  data: () => ({
    services: [
      {
        title: "movingCleaning",
        content: "movingCleaningContent",
        learnMore: false,
        icon: "moving_cleaning"
      },
      {
        title: "homeCleaning",
        content: "homeCleaningContent",
        learnMore: false,
        icon: "house_cleaning"
      },
      {
        title: "officeCleaning",
        content: "officeCleaningContent",
        learnMore: false,
        icon: "office"
      }
    ],
    sliderSettings: {
      arrows: false,
      dots: false
    },
    selectedService: "",
    thankYouDialog: false,
    bookingDialog: false,
    BookingIsLoading: false,
    bookingForm: false,
    formEmail: "",
    formName: "",
    formPhone: "",
    formSpace: "",
    formAddress: "",
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 20 || "Max 20 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    }
  }),
  methods: {
    async validate(token) {
      this.$refs.form.validate();
      console.log(this.bookingForm);
      this.BookingIsLoading = true;

      try {
        let subresult = await axios.post("https://sudtek.se/mail/book", {
          "g-token": token,
          email: this.formEmail,
          name: this.formName,
          address: this.formAddress,
          phone: this.formPhone,
          space: this.formSpace
        });
        console.log("subresult", subresult);
        if (subresult.data.duplicate) {
          console.log("Duplicate", subresult.data.sub);
          if (subresult.data.sub.subscription_status == 1) {
            this.dialogState = 3;
          }
          return;
        }
        if (subresult.data.type == "error") {
          if (subresult.data.text == "tooSoon") this.dialogState = 4;
          return;
        }
        this.emailSub = "";
        this.dialogState = 2;
      } catch (error) {
        console.log("error in ajax", error);
        this.dialog = false;
      }
      setTimeout(() => {
        this.thankYouDialog = true;
        this.bookingDialog = false;
      }, 5000);
    }
  },
  computed: {
    slides() {
      return [
        require("@/assets/bgs/services-slide-show-1.jpg"),
        require("@/assets/bgs/services-slide-show-2.jpg"),
        require("@/assets/bgs/services-slide-show-3.jpg"),
        require("@/assets/bgs/services-slide-show-4.jpg")
      ];
    } /* 
  slides() =>  [
      require("../assets/bgs/service-slide-show-1.jpg"),
      require("../assets/bgs/service-slide-show-2.jpg"),
      require("../assets/bgs/service-slide-show-3.jpg"),
      require("../assets/bgs/service-slide-show-4.jpg"),
    ],
  } */
  },
  components: { VueSlickCarousel }
};
</script>
